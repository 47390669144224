import React from 'react';
import EndImage from './EndImage';
import abbiepic from './abbie picture3.jpg';
import { Helmet } from 'react-helmet';

import pic28 from './img28.jpg';
import pic29 from './img29.jpg';
import pic30 from './img30.jpg';

import ImageGallery from './ImageGallery';

function About() {
  
  const canonicalUrl = `${process.env.REACT_APP_BASE_URL}/about`;

  const aboutImages = [
    { src: pic28, alt: 'An unfinished mid-century armchair being upholstered in a traditional style' },
    { src: pic29, alt: 'Springs exposed from an unfinished antique armchair' },
    { src: pic30, alt: 'An unfinished mid-centrury chair covered in hessian' },  
  ];

  return (

    <section id="about" >
      <Helmet>
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>
      <div className="body-copy">
        <h2 class="page-title">About</h2>
        
        <div className="text-center profile-image">
        <img src={abbiepic} alt="Upholstered, Abbie Laurie, in Peckham, sat smiling on a beautiful sheepskin armchair" />  
        </div>
        <p>My passion for fabric, antiques, and interiors started my journey into upholstery many years ago. I honed my skills through training with The Association of Master Upholsterers and Soft Furnishers (AMUSF) whilst also working in a reputable Upholstery Studio where I collaborated with a team of highly skilled craftspeople on various high-end projects.</p>
        <p>Now, in my own upholstery workshop, in Peckham, South London, I focus on sympathetically restoring furniture using high quality materials using modern or traditional upholstery techniques alongside making bespoke soft furnishing and taking on commissions. I firmly believe in sustainability, the restoration and reupholstering of furniture will minimise landfill waste whilst creating unique pieces for your home or commercial space.</p>
      </div>
      <div className="carousel-panel">
        <ImageGallery images={aboutImages} />
      </div>      
      <><EndImage /></>

    </section>

  );
}

export default About;