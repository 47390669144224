import React from 'react';

import HomeLayout from './HomeLayout';
import Navigation from './Navigation';

import { Helmet } from 'react-helmet';

function Home() {
  const canonicalUrl = `${process.env.REACT_APP_BASE_URL}/home`;

  return (
    <HomeLayout>
      <Helmet>
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>
      <nav className="nav justify-content-center">
        <Navigation />
      </nav>
    </HomeLayout>
  );
}

export default Home;