import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import homeLogo from './home-logo.svg'; 

function HomeLayout({ children }) {
  useEffect(() => {
    document.body.classList.add('home-layout');

    return () => {
      document.body.classList.remove('home-layout');
    };
  }, []);

  return (
    <div className="home-layout-content">
      <Link to="/services" className="home-link">
        <img src={homeLogo} alt="Abbie Laurie Upholstery, Peckham, South East London." className="home-logo" />
        </Link>
        <h2>An independent upholstery workshop in Peckham, South East London.</h2>
      {children}
    </div>
  );
}

export default HomeLayout;