import React from 'react';
import Modal from 'react-modal';

const ImageGallery = ({ images }) => {
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [selectedImage, setSelectedImage] = React.useState(null);

  const openModal = (image) => {
    setIsOpen(true);
    setSelectedImage(image);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const renderImages = () => {
    if (images.length <= 3) {
        return images.map((image, index) => (
            <div 
              key={index} 
              style={{ 
                flex: images.length <= 3 ? 1 : '0 0 100%', 
                maxWidth: images.length <= 3 ? '100%' : 'none' 
              }}
            >
              <img
                src={image.src}
                alt={image.alt}
                onClick={() => openModal(image.src)}
                style={{ width: '100%', objectFit: 'cover' }}
              />
            </div>
          ));
        
    } else {
      return images.map((image, index) => (
        <img
          key={index}
          src={image.src}
          alt={image.alt}
          onClick={() => openModal(image.src)}
        />
      ));
    }
  };

  return (
    <div className="image-gallery" style={images.length <= 3 ? { justifyContent: 'space-between' } : {}}>
      {renderImages()}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Image Modal"
        className="modal-content"
        overlayClassName="modal-overlay"
      >
        <img src={selectedImage} className='image'/>
        <button className="close-button" onClick={closeModal}>Close</button>
      </Modal>
    </div>
  );
};

export default ImageGallery;