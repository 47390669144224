import { NavLink } from 'react-router-dom';

function Navigation() {
  return (
    <nav className="nav justify-content-center my-3">
      <NavLink className="nav-link" activeClassName="active" to="/services">Services</NavLink>
      <NavLink className="nav-link" activeClassName="active" to="/about">About</NavLink>
      <NavLink className="nav-link" activeClassName="active" to="/contact">Contact</NavLink>
    </nav>
  );
}

export default Navigation;