import { Link, useLocation } from 'react-router-dom';
import logo from './logo.svg';
import Navigation from './Navigation';

function Layout({ children }) {
  const location = useLocation();

  return (
    <div className="container">
      {location.pathname !== '/' && (
        <header>
            <Link to="/">
                <img src={logo} alt="Abbie Laurie Upholstery" className="logo" />
            </Link>
          <Navigation />
        </header>
      )}
      {children}
    </div>
  );
}

export default Layout;