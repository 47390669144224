import React from 'react';

import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

import ScrollToTop from './ScrollToTop';
import Layout from './Layout';
import Home from './Home';
import Services from './Services';
import About from './About';
import Contact from './Contact';



function App() {
  
  return (
    <div className="app-wrapper">
      <Router>
      <ScrollToTop />
        <Layout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/services" element={<Services />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
        </Layout>
      </Router>
      
    </div>
  );
}

export default App;
