import React, { useState } from 'react';
import EndImage from './EndImage';
import { Link } from 'react-router-dom';

import { Helmet } from 'react-helmet';

import './Services.css';

import Modal from 'react-modal';

import ImageGallery from './ImageGallery';
import ScrollToTop from './ScrollToTop';

import pic1 from './img1.jpg';
import pic2 from './img2.jpg';
import pic3 from './img3.jpg';
import pic4 from './img4.jpg';
import pic5 from './img5.jpg';
import pic6 from './img6.jpg';
import pic7 from './img7.jpg';
import pic8 from './img8.jpg';
import pic9 from './img9.jpg';
import pic10 from './img10.jpg';
import pic11 from './img11.jpg';
import pic12 from './img12.jpg';
import pic13 from './img13.jpg';
import pic14 from './img14.jpg';
import pic15 from './img15.jpg';
import pic16 from './img16.jpg';
import pic17 from './img17.jpg';
import pic18 from './img18.jpg';
import pic19 from './img19.jpg';
import pic20 from './img20.jpg';
import pic21 from './img21.jpg';
import pic22 from './img22.jpg';
import pic23 from './img23.jpg';
import pic24 from './img24.jpg';
import pic31 from './img31.jpg';

import fabricExamples from './fabric-examples.jpg';

const ReuphosteryImages = [
  { src: pic18, alt: 'A fabulous Clam chair in sheepskin. Carefully selected hides and traditionally upholstered.' },
  { src: pic17, alt: 'A pair of modern arm chairs an maroon suede' },
  { src: pic9, alt: 'A blue arm chair in a living room' },
  { src: pic6, alt: 'A grey sofa finished with contrasting yellow piping' },
  { src: pic7, alt: 'Small blue arm chair' },
  { src: pic16, alt: 'A pair of cream armchairs' },
  { src: pic8, alt: 'An antique high stool' },
  { src: pic14, alt: 'An antique, creame, button-back armchair in Linwood fabric' },
  { src: pic11, alt: 'A tall-backed, low, button-back armchair finished in a leapord skin pattern' },
  { src: pic12, alt: 'A dining chair (with cat)' },
  { src: pic4, alt: 'A white, low, button-back armchair in a light pattern' },
  { src: pic5, alt: 'A large armchair finished in red tartan' },
  { src: pic1, alt: 'An antique dining chair' },
  { src: pic10, alt: 'A modern dining chair in pink' },
  
];

const ComissionImages = [
  { src: pic2, alt: 'An ottoman in the centre of a room' },
  { src: pic3, alt: 'A window with soft seating' },
  { src: pic13, alt: 'A wicker blanket box' },
  { src: pic24, alt: 'A suede headboard in green' }
];

const SoftFurnishingsImages = [
  { src: pic31, alt: 'A pair of maroon bolsters' },
  { src: pic22, alt: 'About 1' },
  { src: pic20, alt: 'About 1' },
  { src: pic21, alt: 'About 1' },
  { src: pic23, alt: 'About 1' },
  { src: pic19, alt: 'About 1' },
  { src: pic15, alt: 'About 5' },

];

Modal.setAppElement('#root');

const Services = () => {
  
  const canonicalUrl = `${process.env.REACT_APP_BASE_URL}/services`;

  const [selectedImage, setSelectedImage] = useState(null);

  return (
    <section id="services">

      <Helmet>
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>

      <h2 className="page-title">Services</h2>
      <div className="service">
        
        
        <div className="carousel-panel">
          <h3 className="section-title">Reupholstery</h3>
          <p>Whether it simply involves replacing the top cover of a piece of traditional, vintage or modern furniture or a reupholster which involves stripping the chair back to its frame and rebuilding the internal elements of the chair using either traditional or modern methods. I work closely with the client discussing the costs and benefits of both in order to create a beautiful bespoke piece.</p>
          <ImageGallery images={ReuphosteryImages} />
          
        </div>

        <div className="carousel-panel">
          <h3 className="section-title">Commissions</h3>
          <p>I can work with the client to design headboards, footstools & ottomans. We can work together from a simple sketch or a photo.</p>
          <ImageGallery images={ComissionImages} />

        </div>
          
          <div className="carousel-panel">
            <h3 className="section-title">Fabric Consulting</h3>
            <img className="image-block" src={fabricExamples} alt="Fabric examples" />
            <p>I hold accounts with many wonderful fabric suppliers so can offer advice and sampling for your project. I can also work with clients own fabric as long as it meets the correct regulations and suitable for the project. </p>
          </div>

          <div className="carousel-panel">
            <h3 className="section-title">Soft furnishings</h3>
            <p>Cushions are a great way to transform any room. Using a variety of fabrics and textures I can make cushions to order in all shapes and sizes from window seats to bolsters.</p>

            <p>Blinds and Curtains can be made to your specifications.</p>
            <ImageGallery images={SoftFurnishingsImages} />
          </div>
      </div>

      <div className="cta-container">
        <h2>Let's start your project</h2>
        <p>Have a family heirloom you've always wanted to refresh? I'd love to hear about it. Get in touch today.</p>
        <ScrollToTop />
        <Link to="/contact">
          <button className="cta-button">Contact me</button>
        </Link>
      </div>

      <><EndImage /></>
    </section>
    
    
  );
};

export default Services;