import React from 'react';
import EndImage from './EndImage';

import { Helmet } from 'react-helmet';

function Contact() {

  const canonicalUrl = `${process.env.REACT_APP_BASE_URL}/contact`;

  return (
    <div>
      <section id="contact">
        <Helmet>
          <link rel="canonical" href={canonicalUrl} />
        </Helmet>
        <div className="body-copy">
          <h2 class="page-title">Contact</h2>

          <div className="nav contact-buttons">
            <button className="nav-link" onClick={() => window.location.href = 'https://wa.me/447822010668'}>
              WhatsApp
            </button>

            <button className="nav-link" onClick={() => window.location.href = 'mailto:hello@abbielaurie.com'}>
              Email
            </button>
          </div>

          <address>
            Unit 2<br />
            Ground Floor Burgess Business Park<br />
            Parkhouse St<br />
            London SE5 7TJ
          </address>
        </div>
        
        <div id="google-map"
          dangerouslySetInnerHTML={{
            __html: `<iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9939.165090598815!2d-0.08625!3d51.4803455!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4876037cbf7e6801%3A0xaefdbbeb9bfdaa3d!2sAbbie%20Laurie%20upholstery!5e0!3m2!1sen!2suk!4v1717453092529!5m2!1sen!2suk" width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>`
          }}
        />
        

      </section>
      <><EndImage /></>
    </div>
  );
}

export default Contact;